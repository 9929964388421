'use client';

import Spinner from '@components/common/spinner';
import { useState } from 'react';

import { endEmailVerification, startEmailVerification, updateUser } from '@actions/userActions';
import BasicModal from '@components/common/modal';
import { useUser } from '@context/userContext';
import EmailConfirmStep from './emailConfirmStep';
import EmailStep from './emailStep';
import InfoStep from './infoStep';

const AuthFlow = ({ initAsLoading = false }: { initAsLoading: boolean }) => {
  const [emailStepDone, setEmailStepDone] = useState<boolean>(false);
  const [emailVerificationStepDone, setEmailVerificationStepDone] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(initAsLoading);
  const [error, setError] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);

  const { authOpen, setAuthOpen, user, setUser } = useUser();

  function setErrorMessage(message: string | null) {
    setError(message);
  }

  async function handleEmailChange(email: string) {
    setLoading(true);
    setEmail(email);
    const result = await startEmailVerification({ email });
    setLoading(false);
    if (!result.success) {
      setError(result.error);
      return;
    }

    setEmailStepDone(true);
  }

  async function handleEmailResend() {
    setLoading(true);
    if (!email) {
      return;
    }
    const result = await startEmailVerification({ email });
    setLoading(false);
    if (!result.success) {
      setError(result.error);
      return;
    }
  }

  async function handleEmailConfirmationChange(code: string) {
    console.log(code);
    setLoading(true);
    if (!email) {
      return;
    }
    const result = await endEmailVerification({ code, email });
    setLoading(false);
    if (!result.success) {
      setError(result.error);
      return;
    }

    setUser(result.user);

    if (result.user?.firstname && result.user?.lastname) {
      setAuthOpen(false);
      setEmailStepDone(false);
      setEmailVerificationStepDone(false);
      return;
    }

    setEmailVerificationStepDone(true);
  }

  async function handleInfoChange(firstname: string, lastname: string) {
    console.log(firstname, lastname);

    setLoading(true);
    const result = await updateUser({ firstname, lastname });
    setLoading(false);

    if (!result.success) {
      setError(result.error);
      return;
    }

    setUser(result.user);

    setAuthOpen(false);
    setEmailStepDone(false);
    setEmailVerificationStepDone(false);
  }

  function onClose() {
    setAuthOpen(false);
    setEmailStepDone(false);
    setEmail('');
    setError(null);
    setEmailVerificationStepDone(false);
  }

  return (
    <BasicModal dismissable={true} openModal={authOpen} closeModalCallback={() => setAuthOpen(false)}>
      <div className="relative w-full max-h-full">
        <div className="w-full mx-auto">
          <div className="flex justify-center">
            <div className="max-w-md mx-auto text-center bg-white">
              {!loading && error ? <div className="text-red-500 mb-8">{error}</div> : null}
              {loading ? <Spinner /> : null}
              {!emailStepDone && !loading ? (
                <EmailStep
                  onChange={handleEmailChange}
                  handleError={setErrorMessage}
                  onClose={onClose}
                  requireAcceptTerms={true}
                />
              ) : null}
              {email && emailStepDone && !emailVerificationStepDone && !loading ? (
                <EmailConfirmStep
                  email={email}
                  onResend={handleEmailResend}
                  onChange={handleEmailConfirmationChange}
                  onClose={onClose}
                  handleError={setErrorMessage}
                />
              ) : null}
              {emailVerificationStepDone && (!user?.firstname || !user?.lastname) && !loading ? (
                <InfoStep
                  firstname={user?.firstname}
                  lastname={user?.lastname}
                  onChange={handleInfoChange}
                  onClose={onClose}
                  handleError={setErrorMessage}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </BasicModal>
  );
};

export default AuthFlow;
