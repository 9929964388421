
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"0156219ee50c5f39b782c4942e65b6106868bbc8":"getUpcomingEvents","0848b393a0363cc5d427760f3f3b62dfc9751db2":"updateUser","1bd8285d960368bda93f64b323c9cd690e0a6f4e":"getUserSession","2e5b1bc603701cef1b827f556fb71db5619d6278":"verifyEmailCode","3d0ce54af93c9234b37a1b1aaa8a65f150363e96":"endEmailVerification","76709de6d67bc61a2ce4b37a34d30750ac9306b4":"logoutAction","8546b716e321ce60c579a768eb47796a42a0aafe":"startEmailVerification","ceab638c723be0ed84d07b1ed30b50457306f2fc":"addNewEmail"} */ export var getUpcomingEvents = createServerReference("0156219ee50c5f39b782c4942e65b6106868bbc8");
import { createServerReference } from "private-next-rsc-action-client-wrapper";
export var getUserSession = createServerReference("1bd8285d960368bda93f64b323c9cd690e0a6f4e");
export var updateUser = createServerReference("0848b393a0363cc5d427760f3f3b62dfc9751db2");
export var startEmailVerification = createServerReference("8546b716e321ce60c579a768eb47796a42a0aafe");
export var endEmailVerification = createServerReference("3d0ce54af93c9234b37a1b1aaa8a65f150363e96");
export var addNewEmail = createServerReference("ceab638c723be0ed84d07b1ed30b50457306f2fc");
export var verifyEmailCode = createServerReference("2e5b1bc603701cef1b827f556fb71db5619d6278");
export var logoutAction = createServerReference("76709de6d67bc61a2ce4b37a34d30750ac9306b4");

