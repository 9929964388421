
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"95823e84671e704eccb14567c427feabe7a0514a":"signIn"} */ export var signIn = createServerReference("95823e84671e704eccb14567c427feabe7a0514a");
import { createServerReference } from "private-next-rsc-action-client-wrapper";

