
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"1f508c4a2fcfa46de61bf3b4b368a8ff65b86684":"releaseItemAction","2211f4e409b1e598894bb86a5c27ec46b3724266":"cartOrderStep","2c6d7b298b7c4c670218a281704fde3c4b2af421":"getCartCountAction","2cc50b67e96bbf013d3e97104b661afc33baabea":"cartEmailCodeStepDone","4b4f4e355657d0e0b4f7eaa19139a5e4f2bc7126":"cartSetStep","4c2aae4ac69cb5d3bf7d0eea17b82991ce7b3649":"cartInfoStep","68cbd9deb7ea613dd83d5940e674c4c00bd36a97":"cartEmailStepDone","68faf4c5315932d1b9adc40957c75bd017aff60c":"getCartAction","725919bdd090761ed9b51f84de69ab459e661828":"cartCompleteStep","7387da58a292054b9c5e795eaf92f35956f061b5":"cartConfirmStep","86b51a92b34482a20a44f6dd6cd626047f9a7f63":"getOrCreateCartAction","8b321cb18df77275a4a51308bf22f797055d0305":"cartFormStep","a0f3ba477db061317fdf1dd9dba6d81740cf99a4":"cancelCartAction","be97c52e6c5782a405c916e06af794f2347f555f":"reserveItemAction","e5d33bcff83bc4f9ee383715d0e2a98b538896e6":"createCartAction"} */ export var cartEmailCodeStepDone = createServerReference("2cc50b67e96bbf013d3e97104b661afc33baabea");
import { createServerReference } from "private-next-rsc-action-client-wrapper";
export var getCartAction = createServerReference("68faf4c5315932d1b9adc40957c75bd017aff60c");
export var getCartCountAction = createServerReference("2c6d7b298b7c4c670218a281704fde3c4b2af421");
export var createCartAction = createServerReference("e5d33bcff83bc4f9ee383715d0e2a98b538896e6");
export var getOrCreateCartAction = createServerReference("86b51a92b34482a20a44f6dd6cd626047f9a7f63");
export var cancelCartAction = createServerReference("a0f3ba477db061317fdf1dd9dba6d81740cf99a4");
export var reserveItemAction = createServerReference("be97c52e6c5782a405c916e06af794f2347f555f");
export var releaseItemAction = createServerReference("1f508c4a2fcfa46de61bf3b4b368a8ff65b86684");
export var cartEmailStepDone = createServerReference("68cbd9deb7ea613dd83d5940e674c4c00bd36a97");
export var cartInfoStep = createServerReference("4c2aae4ac69cb5d3bf7d0eea17b82991ce7b3649");
export var cartFormStep = createServerReference("8b321cb18df77275a4a51308bf22f797055d0305");
export var cartOrderStep = createServerReference("2211f4e409b1e598894bb86a5c27ec46b3724266");
export var cartConfirmStep = createServerReference("7387da58a292054b9c5e795eaf92f35956f061b5");
export var cartCompleteStep = createServerReference("725919bdd090761ed9b51f84de69ab459e661828");
export var cartSetStep = createServerReference("4b4f4e355657d0e0b4f7eaa19139a5e4f2bc7126");

